.tarion_cpg_categories{
    margin-top: 10px;   
    display: flex;
    justify-content: center;
    flex-flow: row wrap; 

    & .category_detail{
        display: inline-flex;
        text-align: center;
        margin-bottom: 20px;
        text-decoration: none;
        min-height: 200px;
        justify-content: center;
        flex: 0 0 33%;
        max-width: 400px;

        & div{
            width: 50%;
            margin: 0 auto;
            min-height: inherit;
            display: inherit;
            align-items: center;
            justify-content: inherit;
            flex-flow: row wrap;

            background: rgb(173,194,228);
            border-radius: 5px;

            & svg{
                flex: 1 1 100%;
            }

            & p{
                margin-top: 4px;
                margin-bottom: 0px;
                color: rgb(89,89,89);
                font-weight: bold;

                //Added cause stupid no image thing
                font-size: 18px;
                color:white;
            }
        }

        &:hover p{
            color: rgb(251,176,59);

            //Added cause stupid no image thing
            color:black;
        }

        &:hover img{
            box-shadow: -5px 6px 10px 0px #fbb03b
        }

        @media screen and (max-width: 980px){
            display:flex;
            text-align: center;
            width: 90%;
            margin: 0 auto 20px auto;
           /* flex: 1 1 90%;*/
        }

        @media screen and (max-width: 840px){
            div{
                width: 90%;
            }
        }

         @media screen and (max-width: 840px){
            flex: 1 1 50%;
        }
    }

}

.tarion-cpg-category_container{
    position: relative;

    @media screen and (max-width: 980px)
        {
            /*overflow-y: scroll;
            height: 70vh;*/
        }
}

.category_back_button{
    position: absolute;
    top: 0px;
    left: 10px;
    margin: 0px;
    margin-top: 5px;
}

.category_header{
    text-align: center;

    @media screen and (max-width: 980px)
        {
           margin-top: 43px;
        }
}