ul.tarion-cpg__breadcrumb-trail {
  display: inline-block;
  margin: 0 1rem;
  padding: 0;
  list-style: none;

  & > li {
    text-transform: uppercase;
    font-size: $font-size-sm;
    display: inline-block;

    @media screen and (max-width:980px){
        display:none;;
    }

    a {
      color: $bread_crumb_active;
      text-decoration: none;
      transition: color 100ms linear;
      font-size: 20px;

      &:hover {
        text-decoration: underline;
      }
    }

    &:last-of-type {
      font-weight: $font-weight-bold;

      & a{
        color: $bread_crumb_active;

        &:hover{          
          text-decoration: underline;
        }
      }
    }

    &::after {
      content: ':';
      padding: 0 .25em;
      color: lighten($grey-dark, 30%);
    }

    &:last-of-type{
        display: inline-block;
        &::after {
            content: '';
            padding: 0;
        }
    }
  }

  & li.left_Bread{
    position: absolute;
    left: 30px;

    &::after {
      content: '';
    }
    
     @media screen and (max-width:980px){
       display:none;
       /* top: 18px;*/
    }
  }

  @media screen and (max-width: 700px){
      position: absolute;
      top: 46px;
      left: 0;
      width: 100%;
      text-align: center;
      margin: 0;
  }

 
}