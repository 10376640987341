#tarion-cpg__main{
    
    &.exterior_columns{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.exterior_columns{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.exterior_columns, p.exterior_columns{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.landscaping{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.landscaping{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.landscaping, p.landscaping{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.barrier_free{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.barrier_free{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.barrier_free, p.barrier_free{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.structure{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.structure{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.structure, p.structure{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.fire_safety{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.fire_safety{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.fire_safety, p.fire_safety{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.landscape_deck{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.landscape_deck{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.landscape_deck, p.landscape_deck{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.roof{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.roof{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.roof, p.roof{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.exterior_cladding{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.exterior_cladding{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.exterior_cladding, p.exterior_cladding{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.electrical{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.electrical{
                display: none;
            }
        }
        .tarion-cpg__sidebar-container{
            a.electrical, p.electrical{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.foundation{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.foundation{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.foundation, p.foundation{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.landing{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.landing{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.landing, p.landing{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.exterior_windows{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.exterior_windows{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.exterior_windows, p.exterior_windows{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.exterior_doors_man{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.exterior_doors_man{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.exterior_doors_man, p.exterior_doors_man{
                color:white;
                background: $tarion-blue;
            }
        }

        .modal a.exterior_doors_man{
            color:white;
            background: $tarion-blue;
        }
    }

     &.exterior_doors_man.exterior_doors{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.exterior_doors_man{
                display: none;
            }

             .hide.exterior_doors{
                display: none;
            }
        }   
    }

    &.exterior_doors_man.doors,&.exterior_doors.doors{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.exterior_doors_man{
                display: none;
            }

             .hide.exterior_doors{
                display: none;
            }
        }   
    }

    &.exterior_doors{
        .hide{
            display:block;
        } 
        
        .hide.exterior_doors{
            display: none;
        }

        .tarion-cpg__sidebar-container{
            a.exterior_doors, p.exterior_doors{
                color:white;
                background: $tarion-blue;
            }
        }

        .modal a.exterior_doors{
            color:white;
            background: $tarion-blue;
        }
    }

    &.exterior{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.exterior{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.exterior, p.exterior{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.townhome{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.townhome{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.townhome, p.townhome{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.townhome_unit{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.townhome_unit{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.townhome_unit, p.townhome_unit{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.interior{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.interior{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.interior, p.interior{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.condo{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.condo{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.condo, p.condo{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.freehold{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.freehold{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.freehold, p.freehold{
                color:white;
                background: $tarion-blue;
            }
        }
    }

     &.condo_unit{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.condo_unit{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.condo_unit, p.condo_unit{
                color:white;
                background: $tarion-blue;
            }
        }
    }

     &.common_element{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.common_element{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.common_element, p.common_element{
                color:white;
                background: $tarion-blue;
            }
        }
    }

     &.bedroom{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.bedroom{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.bedroom, p.bedroom{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.bathroom{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.bathroom{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.bathroom, p.bathroom{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.hallway{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.hallway{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.hallway, p.hallway{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.attic{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.attic{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.attic, p.attic{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.garage{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.garage{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.garage, p.garage{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.living_room{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.living_room{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.living_room, p.living_room{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.kitchen{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.kitchen{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.kitchen, p.kitchen{
                color:white;
                background: $tarion-blue;
            }
        }
    }

      &.cold_room{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.cold_room{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.cold_room, p.cold_room{
                color:white;
                background: $tarion-blue;
            }
        }
    }

      &.basement_unfinished{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.basement_unfinished{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.basement_unfinished, p.basement_unfinished{
                color:white;
                background: $tarion-blue;
            }
        }

        .modal a.basement_unfinished{
            color:white;
            background: $tarion-blue;
        }
    }

      &.basement_finished{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.basement_finished{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.basement_finished, p.basement_finished{
                color:white;
                background: $tarion-blue;
            }
        }

        .modal a.basement_finished{
            color:white;
            background: $tarion-blue;
        }
    }

      &.laundry{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.laundry{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.laundry, p.laundry{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.climate_control{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.climate_control{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.climate_control, p.climate_control{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.interior_doors{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.interior_doors{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.interior_doors, p.interior_doors{
                color:white;
                background: $tarion-blue;
            }
        }

        .modal a.interior_doors{
            color:white;
            background: $tarion-blue;
        }
    }

    &.trim{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.trim{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.trim, p.trim{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.interior_finishes{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.interior_finishes{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.interior_finishes, p.interior_finishes{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.flooring{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.flooring{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.flooring, p.flooring{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.conveying_systems{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.conveying_systems{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.conveying_systems, p.conveying_systems{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.mechanical{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.mechanical{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.mechanical, p.mechanical{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.ceiling{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.ceiling{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.ceiling, p.ceiling{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.wall{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.wall{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.wall, p.wall{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.windows{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.windows{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.windows, p.windows{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.ce_windows{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.ce_windows{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.ce_windows, p.ce_windows{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.countertops{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.countertops{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.countertops, p.countertops{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.cabinets{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.cabinets{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.cabinets, p.cabinets{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.plumbing{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.plumbing{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.plumbing, p.plumbing{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.stairs{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.stairs{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.stairs, p.stairs{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.fireplace{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.fireplace{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.fireplace, p.fireplace{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.cold_electrical{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.cold_electrical{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.cold_electrical, p.cold_electrical{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.garage_floor{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.garage_floor{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.garage_floor, p.garage_floor{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.cold_room_door{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.cold_room_door{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.cold_room_door, p.cold_room_door{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.unfinished_floor{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.unfinished_floor{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.unfinished_floor, p.unfinished_floor{
                color:white;
                background: $tarion-blue;
            }
        }
    }

     &.finished_flooring{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.finished_flooring{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.finished_flooring, p.finished_flooring{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.unfinished_wall{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.unfinished_wall{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.unfinished_wall, p.unfinished_wall{
                color:white;
                background: $tarion-blue;
            }
        }
    }

     &.finished_wall{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.finished_wall{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.finished_wall, p.finished_wall{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.unfinished_plumbing{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.unfinished_plumbing{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.unfinished_plumbing, p.unfinished_plumbing{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.basement_finished_doors{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.basement_finished_doors{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.basement_finished_doors, p.basement_finished_doors{
                color:white;
                background: $tarion-blue;
            }
        }
    }

     &.entry{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.entry{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.entry, p.entry{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.driveway{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.driveway{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.driveway, p.driveway{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.landscape{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.landscape{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.landscape, p.landscape{
                color:white;
                background: $tarion-blue;
            }
        }
    }

     &.landscaping{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.landscaping{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.landscaping, p.landscaping{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.decks{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.decks{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.decks, p.decks{
                color:white;
                background: $tarion-blue;
            }
        }
    }

     &.balcony{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.balcony{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.balcony, p.balcony{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.chimney{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.chimney{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.chimney, p.chimney{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.shingles{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.shingles{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.shingles, p.shingles{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.eavestroughs{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.eavestroughs{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.eavestroughs, p.eavestroughs{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.skylight{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.skylight{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.skylight, p.skylight{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.roofs{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.roofs{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.roofs, p.roofs{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.exterior_paint{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.exterior_paint{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.exterior_paint, p.exterior_paint{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.amenity_room{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.amenity_room{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.amenity_room, p.amenity_room{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.garage_exterior_door{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.garage_exterior_door{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.garage_exterior_door, p.garage_exterior_door{
                color:white;
                background: $tarion-blue;
            }
        }
    }



    &.ground_lobby{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.ground_lobby{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.ground_lobby, p.ground_lobby{
                color:white;
                background: $tarion-blue;
            }
        }
    }

     &.building_exterior{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.building_exterior{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.building_exterior, p.building_exterior{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.parking_garage{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.parking_garage{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.parking_garage, p.parking_garage{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.garbage_recycling{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.garbage_recycling{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.garbage_recycling, p.garbage_recycling{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.pool_area{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.pool_area{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.pool_area, p.pool_area{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.corridors{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.corridors{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.corridors, p.corridors{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.exit_stairs{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.exit_stairs{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.exit_stairs, p.exit_stairs{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.elevators{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.elevators{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.elevators, p.elevators{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.mechanical_room{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.mechanical_room{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.mechanical_room, p.mechanical_room{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.barrier_free{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.barrier_free{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.barrier_free, p.barrier_free{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.parking_painting{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.parking_painting{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.parking_painting, p.parking_painting{
                color:white;
                background: $tarion-blue;
            }
        }
    }

     &.garabage_recycling{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.garabage_recycling{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.garabage_recycling, p.garabage_recycling{
                color:white;
                background: $tarion-blue;
            }
        }
    }

    &.exterior_closure{
        .tarion-cpg__main-container svg{
            .hide{
                display:block;
            } 
            
            .hide.exterior_closure{
                display: none;
            }
        }

        .tarion-cpg__sidebar-container{
            a.exterior_closure, p.exterior_closure{
                color:white;
                background: $tarion-blue;
            }
        }
    }
}