#tarion-cpg__header {
  align-items: center;
  background-color: rgba($white, .5);
  padding-bottom: 0px;

  .header_crumb_search{
    .tarion-cpg__back-btn{
        background:none;
        padding: 0px;
        margin: 0px;
        position: absolute;
        left: 30px;
        top: 8px;
        display: block;
        box-shadow: none;

        svg{
            fill: white;
        }


        @media screen and (min-width: $mobile-size + 1){
            display:none;
        }
    }
  }
}

#tarion-cpg__header h1 {
  font-size: $font-size-h1;  
}

#tarion-cpg__header #h2 {
  font-size: $font-size-h2;  
}

#tarion-cpg__header div.header_logo{
  display: block;
  background: white;
  margin-left: 2rem;

  @media screen and (max-width:980px){
        margin-top:8px;
        margin-left: 8px;
    }

    @media screen and (max-width:600px){
        text-align: center;
        width: 100%;

        svg{
            width: auto;
            height: 30px;
        }

        h1{
            font-size: 1.5rem;
        }
    }

  & h1, #h2{
    display: inline-block;
    margin: 0px;
    margin-left: 10px;
    color:$header_blue;
    padding-bottom: 4px;
  }

  & #h2{
    display:block;
    margin-left: 0px;

    @media screen and (max-width:980px){
        display:none;
    }
  }
}

@media screen and (max-width:1282px){
    #tarion-cpg__header h1 {
        font-size: $font-size-h2;  
    }

    #tarion-cpg__header #h2 {
        font-size: $font-size-h3;  
    }

}

