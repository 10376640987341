/**
 * Default values, derived from Twitter Bootstrap v4.
 * @see https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss
 */
 
$white:               #fffefe !default;
$grey-light:          #EEE !default;
$grey:                #DDD !default;
$grey-dark:           #393939 !default;
$grey-dark2:          #424242 !default;
$grey2:               #9F9F9F !default;
$black:               black !default;
$blue:                #4b819a !default;
$text-blue:           #224E85 !default;
$article-blue:        #27588d !default;
$header_blue:         rgb(48,99,152) !default;
$border_grey:         rgb(139,151,165) !default;
$bread_crumb_main:    rgb(182,197,217) !default;
$bread_crumb_active:  #fffefe !default;
$hover_link:          rgb(240,0,0) !default;
$barf_yellow:         rgba(251,176,59, 1);
$background:          #F1F1F1 !default;
$tarion-blue:         #4f87b2 !default;

$sky_color_blue: rgb(188,219,240);
$sky_color_white: rgb(244,249,253);

$font-size-base:        1rem !default;
$font-size-lg:          1.25rem !default;
$font-size-sm:          .875rem !default;
$font-size-xs:          .75rem !default;

$font-weight-light:     300 !default;
$font-weight-normal:    400 !default;
$font-weight-bold:      700 !default;

$line-height:           1.5 !default;

$font-size-h1:          2.5rem !default;
$font-size-h2:          2rem !default;
$font-size-h3:          28px !default;
$font-size-h4:          1.5rem !default;
$font-size-h5:          1.25rem !default;
$font-size-h6:          1rem !default;

$font-family:           Roboto,
                        "Segoe UI",
                        "Helvetica Neue",
                        Arial,
                        sans-serif !default;

$letter-spacing:        0px;
$less-letter-spacing:   -.5px;
$extra-letter-spacing:  .5px;

$padding-default:       1rem !default;

$border-radius-default: 3px;

$box-shadow-default:    0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$box-shadow-active:     0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

$table_red: rgb(250,191,143);
$table_blue: rgb(146,205,220);

$break-header: 1119px;
$break-header2 : 980px;
$break-header3: 575px;
$mobile-size: 980px;