#tarion-cpg__search {
  height: 100%;
  width: 100%;
}

#tarion-cpg__search-form,
#tarion-cpg__search-results, {
  width: 100%;
  max-width: 600px;
  margin: 2rem auto;
}

#tarion-cpg__search-results {
  background-color: $white;
  box-shadow: $box-shadow-default;
}

#tarion-cpg__search-results-header,
#tarion-cpg__search-results-main {
  padding: 1rem $padding-default;
}

#tarion-cpg__search-results-header {
  display: flex;
  align-items: center;

  h2 {
    flex: 1;
    margin: 0;
  }
}

#tarion-cpg__search-form .tarion-cpg__search-form {
  margin: 0;
  padding: $padding-default;
  font-size: 1.5em;
  background-color: $white;
  box-shadow: $box-shadow-default;  
}

form .tarion-cpg__search-input{
    @media screen and (max-width: 980px)
    {
        text-align: left;
        padding-left: 4px;
        height: 36px;
    }
  }

.articleContainer.search h2{
    display: inline;
}

.article_back_button.search{
    text-align: center;

    & button{
        display:inline-block;
        float: left;

        @media screen and (max-width: 980px){
            display:none;
        }
    }
}

.mobile_show {
    display:none;

    @media screen and (max-width: 980px)
    {
        display:inline-block;
    }
}

.tarion-cpg__nav ul > li > a.mobile_show{
    display:none;

    @media screen and (max-width: 980px)
    {
        display:block;
    }
}

.mobile_hide{

    @media screen and (max-width: 980px)
    {
        display:none !important;
    }
}

.mobile_show.submit{
    position: absolute;
    right: 0px;
    height: 36px;
    border-left: 1px solid black;
    top:0px;
    box-sizing: border-box;
}