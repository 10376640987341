html{
    @media screen and (max-width: $mobile-size){
        overflow-x: hidden;
    }
}

body {
  margin: 0;
  padding: 0;
  color: $grey-dark;
  font-family: $font-family;
  height: 100vh;
  overflow: hidden;

   @media screen and (max-width: $mobile-size){
      height: auto;
  }
}

#tarion-cpg__root {
  height: 100%;

   @media screen and (max-width: 980px){
      height: auto;
  }
}

#tarion-cpg__app {
  height: 100%;

   @media screen and (max-width: 980px){
      height: auto;
  }
}

#tarion-cpg__main {
  height: calc((100%) - (90px));

  @media screen and (min-width: $break-header2) {
    height: calc((100%) - 219px);
  }

  @media screen and (min-width: $break-header) {
    height: calc((100%) - (180px));
  }

  @media screen and (max-width: 980px){
      height: auto;
  }
}

.tarion-cpg__sidebar-container {
  z-index: 9999;
}

.tarion-cpg__mobile {
  width: 90%;
  margin: 0 auto;

  .tarion-cpg__mobile-header{
      text-align: center;
      margin-bottom: 0px;
  }
}

.tarion-cpg__main-container {
  display:inline-block;
  width: calc((100%) - (350px));
  height: 100%;
  vertical-align: top;
}

.tarion-cpg__container {
  height: 100%;

  @media screen and (max-width: $mobile-size){
      /*display:none;*/
      height: auto;
  }
}

.tarion-cpg_two-container {
  position: relative;
  height: 100%;
  max-height: 100%;
  width: 100%;

  .tarion-cpg__svg-container {
    width: 50%;
    cursor:pointer;
    display: inline-block;

    &.house > svg{
      /* margin-bottom: 10%;*/
    }

    &.move_right > svg{
      /*margin-left: 40px;*/
        transform: rotate(180deg);
    }

    &.condo_unit2 > svg{
      -ms-transform: rotate(270deg);
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg);
    }

    & > svg {
      position: relative;
      top: auto;
      width: auto;
      height: auto;
    }
  }
}

.tarion-cpg__mobile-container {
  position: relative;
  width: 100%;

  .tarion-cpg__svg-container {
    /*width: 50%;
    cursor:pointer;
    display: inline-block;*/
    position: relative;
    display: block;

    & > svg {
        position: relative;
      top: 0px;
      width: 100%;
      height: auto;
    }

    & p{
        text-align: center;
        margin-top: -10px;
        margin-bottom: 20px;
        font-weight: 600;
        text-transform: uppercase;
    }
  }

  &.small{
    display: flex;
    justify-content: space-evenly;
    align-items:baseline;
    flex-flow: row wrap;

    .tarion-cpg__svg-container {
        /*width: 50%;
        cursor:pointer;
        display: inline-block;*/
        position: relative;
        flex: 0 1 33%;
        display: inline-block;
        max-width: 33%;

        p{
            max-width: 100%;
            word-wrap: break-word;
        }
    }

    &.small_box{
       div{ 
            flex: 1 1 33%;
            min-height: 200px;
            vertical-align: middle;
            
            p{
                min-height: 110px;
                padding-top: 90px;
                margin: 20px;
                background: #adc2e4;
                border-radius: 5px;
                color:white;
                min-width: 140px;
            }
       }
    }
  }
}

.tarion-cpg__svg-container {
  position: relative;
  height: 100%;
  max-height: 100%;
  width: 100%;

    &.condo_unit2.house > svg{
     /* margin-bottom: 10%;*/
    }

    &.move_right > svg{
      /*margin-left: 40px;*/
        transform: rotate(180deg);
    }

    &.condo_unit2 > svg{
      -ms-transform: rotate(270deg);
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg);
    }

  & > svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    max-height: 100%;
    width: 100%;
  }
}

.tarion-cpg__with-sidebar {
  height: 100%;

  @media screen and (max-width: $mobile-size){
      min-width: 100%;
      max-width: 100%;
      width: 100%;
      text-align: center;
      height: auto;
  }
}

.tarion-cpg__with-sidebar > .tarion-cpg__sidebar-container {
  display:inline-block;
  height: calc((100%) - (32px));
  margin-top: 2 * $padding-default;
  margin-left: 2 * $padding-default;
  max-width: 318px;
  position: relative;

  @media screen and (max-width: $mobile-size){
      width: 98%;
      margin: 0 auto;
      text-align: left;
      max-width: 100%;
      height: auto;
  }
}

.cls-777{
    opacity: 0.4;
}

.cls-888{
    fill: transparent;
}

.blend_svg{
    mix-blend-mode: multiply;
}

.hide{
    display:none;
}

.pointer{
  cursor:pointer;
}

.sky_color_blue{
    stop-color: $sky_color_blue;
    stop-opacity: 1;
}

.blue{
    cursor: pointer;
    color:#26588D;
}

.sky_color_white{
    stop-color: $sky_color_white;
    stop-opacity: 1;
}

.header_crumb_search{
  background: #26588D;
  border-top: 2px solid $border_grey;
  border-bottom: 2px solid $border_grey;  
  padding: 4px;
  padding: 18px;
  position: relative;
  text-align: center;
  z-index: 2;

  @media screen and (max-width:700px){
        height: 38px;
    }
}

a{
  font-size: 15px;
}

.landscape_deck.cls-33{
    fill: #ccc;
}

#color_override_1{
    fill:#e6e6e6;
}

/*janky detect IE css*/
@media all and (-ms-high-contrast:none)
{
    .ie-house > svg { margin-bottom: 0px !important; }

    .hide_ie{ display:none; }
}

@supports (-ms-accelerator:true) {
    .hide_ie{ display:none; }   
}

@supports (-ms-ime-align:auto) {
    .hide_ie{ display:none; }    
}

.explain{
    a{
        font-size: 16px;
    }

    @media screen and (min-width: 981px){
        max-height: calc(100% - 280px);
        overflow-y: auto;
    }
}
