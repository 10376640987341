.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .25em .5em;
  border: none;
  border-radius: $border-radius-default;
  outline: none;
  font-size: 1em;
  line-height: 1;
  background: $white;
  color: $grey-dark;
  fill: $grey-dark;
  transition: all 100ms linear;

  &:hover {
    cursor: pointer;
    box-shadow: $box-shadow-default;
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }
}

button {
  @extend .btn;
}

.btn--translucent-white {
  background: rgba($white, .5);
}

.btn--icon-only {
  @extend .btn;
  height: 2.5em;
  width: 2.5em;
  padding: .25em;
  border-radius: 50%;

  & > span {
    @extend .sr-only;
  }
  
  & > svg {
    height: 1.5em;
    width: 1.5em;
    fill: rgb(75, 129, 154);
  }
}