@import "https://fonts.googleapis.com/css?family=Roboto:400,700";
.react-slidedown {
  height: 0;
  transition-property: none;
  transition-duration: .5s;
  transition-timing-function: ease-in-out;
}

.react-slidedown.transitioning {
  overflow-y: hidden;
}

.react-slidedown.closed {
  display: none;
}

.sr-only, .tarion-cpg__search-form > label, .btn--icon-only > span, .tarion-cpg__search-btn > span, .tarion-cpg__reset-btn > span, .tarion-cpg__close-btn > span, .tarion-cpg__back-btn > span {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

@media screen and (width <= 980px) {
  html {
    overflow-x: hidden;
  }
}

body {
  color: #393939;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Roboto, Segoe UI, Helvetica Neue, Arial, sans-serif;
  overflow: hidden;
}

@media screen and (width <= 980px) {
  body {
    height: auto;
  }
}

#tarion-cpg__root {
  height: 100%;
}

@media screen and (width <= 980px) {
  #tarion-cpg__root {
    height: auto;
  }
}

#tarion-cpg__app {
  height: 100%;
}

@media screen and (width <= 980px) {
  #tarion-cpg__app {
    height: auto;
  }
}

#tarion-cpg__main {
  height: calc(100% - 90px);
}

@media screen and (width >= 980px) {
  #tarion-cpg__main {
    height: calc(100% - 219px);
  }
}

@media screen and (width >= 1119px) {
  #tarion-cpg__main {
    height: calc(100% - 180px);
  }
}

@media screen and (width <= 980px) {
  #tarion-cpg__main {
    height: auto;
  }
}

.tarion-cpg__sidebar-container {
  z-index: 9999;
}

.tarion-cpg__mobile {
  width: 90%;
  margin: 0 auto;
}

.tarion-cpg__mobile .tarion-cpg__mobile-header {
  text-align: center;
  margin-bottom: 0;
}

.tarion-cpg__main-container {
  vertical-align: top;
  width: calc(100% - 350px);
  height: 100%;
  display: inline-block;
}

.tarion-cpg__container {
  height: 100%;
}

@media screen and (width <= 980px) {
  .tarion-cpg__container {
    height: auto;
  }
}

.tarion-cpg_two-container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
}

.tarion-cpg_two-container .tarion-cpg__svg-container {
  cursor: pointer;
  width: 50%;
  display: inline-block;
}

.tarion-cpg_two-container .tarion-cpg__svg-container.move_right > svg {
  transform: rotate(180deg);
}

.tarion-cpg_two-container .tarion-cpg__svg-container.condo_unit2 > svg {
  transform: rotate(270deg);
}

.tarion-cpg_two-container .tarion-cpg__svg-container > svg {
  width: auto;
  height: auto;
  position: relative;
  top: auto;
}

.tarion-cpg__mobile-container {
  width: 100%;
  position: relative;
}

.tarion-cpg__mobile-container .tarion-cpg__svg-container {
  display: block;
  position: relative;
}

.tarion-cpg__mobile-container .tarion-cpg__svg-container > svg {
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
}

.tarion-cpg__mobile-container .tarion-cpg__svg-container p {
  text-align: center;
  text-transform: uppercase;
  margin-top: -10px;
  margin-bottom: 20px;
  font-weight: 600;
}

.tarion-cpg__mobile-container.small {
  flex-flow: wrap;
  justify-content: space-evenly;
  align-items: baseline;
  display: flex;
}

.tarion-cpg__mobile-container.small .tarion-cpg__svg-container {
  flex: 0 33%;
  max-width: 33%;
  display: inline-block;
  position: relative;
}

.tarion-cpg__mobile-container.small .tarion-cpg__svg-container p {
  word-wrap: break-word;
  max-width: 100%;
}

.tarion-cpg__mobile-container.small.small_box div {
  vertical-align: middle;
  flex: 33%;
  min-height: 200px;
}

.tarion-cpg__mobile-container.small.small_box div p {
  color: #fff;
  background: #adc2e4;
  border-radius: 5px;
  min-width: 140px;
  min-height: 110px;
  margin: 20px;
  padding-top: 90px;
}

.tarion-cpg__svg-container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
}

.tarion-cpg__svg-container.move_right > svg {
  transform: rotate(180deg);
}

.tarion-cpg__svg-container.condo_unit2 > svg {
  transform: rotate(270deg);
}

.tarion-cpg__svg-container > svg {
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: absolute;
  inset: 0;
}

.tarion-cpg__with-sidebar {
  height: 100%;
}

@media screen and (width <= 980px) {
  .tarion-cpg__with-sidebar {
    text-align: center;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.tarion-cpg__with-sidebar > .tarion-cpg__sidebar-container {
  max-width: 318px;
  height: calc(100% - 32px);
  margin-top: 2rem;
  margin-left: 2rem;
  display: inline-block;
  position: relative;
}

@media screen and (width <= 980px) {
  .tarion-cpg__with-sidebar > .tarion-cpg__sidebar-container {
    text-align: left;
    width: 98%;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

.cls-777 {
  opacity: .4;
}

.cls-888 {
  fill: #0000;
}

.blend_svg {
  mix-blend-mode: multiply;
}

.hide {
  display: none;
}

.pointer {
  cursor: pointer;
}

.sky_color_blue {
  stop-color: #bcdbf0;
  stop-opacity: 1;
}

.blue {
  cursor: pointer;
  color: #26588d;
}

.sky_color_white {
  stop-color: #f4f9fd;
  stop-opacity: 1;
}

.header_crumb_search {
  text-align: center;
  z-index: 2;
  background: #26588d;
  border-top: 2px solid #8b97a5;
  border-bottom: 2px solid #8b97a5;
  padding: 18px;
  position: relative;
}

@media screen and (width <= 700px) {
  .header_crumb_search {
    height: 38px;
  }
}

a {
  font-size: 15px;
}

.landscape_deck.cls-33 {
  fill: #ccc;
}

#color_override_1 {
  fill: #e6e6e6;
}

@media (-ms-high-contrast: none) {
  .ie-house > svg {
    margin-bottom: 0 !important;
  }

  .hide_ie {
    display: none;
  }
}

@supports (-ms-accelerator: true) {
  .hide_ie {
    display: none;
  }
}

@supports (-ms-ime-align: auto) {
  .hide_ie {
    display: none;
  }
}

.explain a {
  font-size: 16px;
}

@media screen and (width >= 981px) {
  .explain {
    max-height: calc(100% - 280px);
    overflow-y: auto;
  }
}

.btn, .btn--icon-only, .tarion-cpg__search-btn, .tarion-cpg__reset-btn, .tarion-cpg__close-btn, .tarion-cpg__back-btn, button {
  color: #393939;
  fill: #393939;
  background: #fffefe;
  border: none;
  border-radius: 3px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: .25em .5em;
  font-size: 1em;
  line-height: 1;
  transition: all .1s linear;
  display: flex;
}

.btn:hover, .btn--icon-only:hover, .tarion-cpg__search-btn:hover, .tarion-cpg__reset-btn:hover, .tarion-cpg__close-btn:hover, .tarion-cpg__back-btn:hover, button:hover {
  cursor: pointer;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
}

.btn:disabled, .btn--icon-only:disabled, .tarion-cpg__search-btn:disabled, .tarion-cpg__reset-btn:disabled, .tarion-cpg__close-btn:disabled, .tarion-cpg__back-btn:disabled, button:disabled {
  opacity: .5;
  cursor: not-allowed;
}

.btn--translucent-white {
  background: #fffefe80;
}

.btn--icon-only, .tarion-cpg__search-btn, .tarion-cpg__reset-btn, .tarion-cpg__close-btn, .tarion-cpg__back-btn {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  padding: .25em;
}

.btn--icon-only > svg, .tarion-cpg__search-btn > svg, .tarion-cpg__reset-btn > svg, .tarion-cpg__close-btn > svg, .tarion-cpg__back-btn > svg {
  fill: #4b819a;
  width: 1.5em;
  height: 1.5em;
}

#tarion-cpg__main.exterior_columns .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.exterior_columns .tarion-cpg__main-container svg .hide.exterior_columns {
  display: none;
}

#tarion-cpg__main.exterior_columns .tarion-cpg__sidebar-container a.exterior_columns, #tarion-cpg__main.exterior_columns .tarion-cpg__sidebar-container p.exterior_columns {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.structure .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.structure .tarion-cpg__main-container svg .hide.structure {
  display: none;
}

#tarion-cpg__main.structure .tarion-cpg__sidebar-container a.structure, #tarion-cpg__main.structure .tarion-cpg__sidebar-container p.structure {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.fire_safety .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.fire_safety .tarion-cpg__main-container svg .hide.fire_safety {
  display: none;
}

#tarion-cpg__main.fire_safety .tarion-cpg__sidebar-container a.fire_safety, #tarion-cpg__main.fire_safety .tarion-cpg__sidebar-container p.fire_safety {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.landscape_deck .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.landscape_deck .tarion-cpg__main-container svg .hide.landscape_deck {
  display: none;
}

#tarion-cpg__main.landscape_deck .tarion-cpg__sidebar-container a.landscape_deck, #tarion-cpg__main.landscape_deck .tarion-cpg__sidebar-container p.landscape_deck {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.roof .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.roof .tarion-cpg__main-container svg .hide.roof {
  display: none;
}

#tarion-cpg__main.roof .tarion-cpg__sidebar-container a.roof, #tarion-cpg__main.roof .tarion-cpg__sidebar-container p.roof {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.exterior_cladding .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.exterior_cladding .tarion-cpg__main-container svg .hide.exterior_cladding {
  display: none;
}

#tarion-cpg__main.exterior_cladding .tarion-cpg__sidebar-container a.exterior_cladding, #tarion-cpg__main.exterior_cladding .tarion-cpg__sidebar-container p.exterior_cladding {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.electrical .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.electrical .tarion-cpg__main-container svg .hide.electrical {
  display: none;
}

#tarion-cpg__main.electrical .tarion-cpg__sidebar-container a.electrical, #tarion-cpg__main.electrical .tarion-cpg__sidebar-container p.electrical {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.foundation .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.foundation .tarion-cpg__main-container svg .hide.foundation {
  display: none;
}

#tarion-cpg__main.foundation .tarion-cpg__sidebar-container a.foundation, #tarion-cpg__main.foundation .tarion-cpg__sidebar-container p.foundation {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.landing .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.landing .tarion-cpg__main-container svg .hide.landing {
  display: none;
}

#tarion-cpg__main.landing .tarion-cpg__sidebar-container a.landing, #tarion-cpg__main.landing .tarion-cpg__sidebar-container p.landing {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.exterior_windows .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.exterior_windows .tarion-cpg__main-container svg .hide.exterior_windows {
  display: none;
}

#tarion-cpg__main.exterior_windows .tarion-cpg__sidebar-container a.exterior_windows, #tarion-cpg__main.exterior_windows .tarion-cpg__sidebar-container p.exterior_windows {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.exterior_doors_man .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.exterior_doors_man .tarion-cpg__main-container svg .hide.exterior_doors_man {
  display: none;
}

#tarion-cpg__main.exterior_doors_man .tarion-cpg__sidebar-container a.exterior_doors_man, #tarion-cpg__main.exterior_doors_man .tarion-cpg__sidebar-container p.exterior_doors_man, #tarion-cpg__main.exterior_doors_man .modal a.exterior_doors_man {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.exterior_doors_man.exterior_doors .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.exterior_doors_man.exterior_doors .tarion-cpg__main-container svg .hide.exterior_doors_man, #tarion-cpg__main.exterior_doors_man.exterior_doors .tarion-cpg__main-container svg .hide.exterior_doors {
  display: none;
}

#tarion-cpg__main.exterior_doors_man.doors .tarion-cpg__main-container svg .hide, #tarion-cpg__main.exterior_doors.doors .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.exterior_doors_man.doors .tarion-cpg__main-container svg .hide.exterior_doors_man, #tarion-cpg__main.exterior_doors.doors .tarion-cpg__main-container svg .hide.exterior_doors_man, #tarion-cpg__main.exterior_doors_man.doors .tarion-cpg__main-container svg .hide.exterior_doors, #tarion-cpg__main.exterior_doors.doors .tarion-cpg__main-container svg .hide.exterior_doors {
  display: none;
}

#tarion-cpg__main.exterior_doors .hide {
  display: block;
}

#tarion-cpg__main.exterior_doors .hide.exterior_doors {
  display: none;
}

#tarion-cpg__main.exterior_doors .tarion-cpg__sidebar-container a.exterior_doors, #tarion-cpg__main.exterior_doors .tarion-cpg__sidebar-container p.exterior_doors, #tarion-cpg__main.exterior_doors .modal a.exterior_doors {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.exterior .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.exterior .tarion-cpg__main-container svg .hide.exterior {
  display: none;
}

#tarion-cpg__main.exterior .tarion-cpg__sidebar-container a.exterior, #tarion-cpg__main.exterior .tarion-cpg__sidebar-container p.exterior {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.townhome .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.townhome .tarion-cpg__main-container svg .hide.townhome {
  display: none;
}

#tarion-cpg__main.townhome .tarion-cpg__sidebar-container a.townhome, #tarion-cpg__main.townhome .tarion-cpg__sidebar-container p.townhome {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.townhome_unit .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.townhome_unit .tarion-cpg__main-container svg .hide.townhome_unit {
  display: none;
}

#tarion-cpg__main.townhome_unit .tarion-cpg__sidebar-container a.townhome_unit, #tarion-cpg__main.townhome_unit .tarion-cpg__sidebar-container p.townhome_unit {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.interior .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.interior .tarion-cpg__main-container svg .hide.interior {
  display: none;
}

#tarion-cpg__main.interior .tarion-cpg__sidebar-container a.interior, #tarion-cpg__main.interior .tarion-cpg__sidebar-container p.interior {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.condo .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.condo .tarion-cpg__main-container svg .hide.condo {
  display: none;
}

#tarion-cpg__main.condo .tarion-cpg__sidebar-container a.condo, #tarion-cpg__main.condo .tarion-cpg__sidebar-container p.condo {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.freehold .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.freehold .tarion-cpg__main-container svg .hide.freehold {
  display: none;
}

#tarion-cpg__main.freehold .tarion-cpg__sidebar-container a.freehold, #tarion-cpg__main.freehold .tarion-cpg__sidebar-container p.freehold {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.condo_unit .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.condo_unit .tarion-cpg__main-container svg .hide.condo_unit {
  display: none;
}

#tarion-cpg__main.condo_unit .tarion-cpg__sidebar-container a.condo_unit, #tarion-cpg__main.condo_unit .tarion-cpg__sidebar-container p.condo_unit {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.common_element .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.common_element .tarion-cpg__main-container svg .hide.common_element {
  display: none;
}

#tarion-cpg__main.common_element .tarion-cpg__sidebar-container a.common_element, #tarion-cpg__main.common_element .tarion-cpg__sidebar-container p.common_element {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.bedroom .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.bedroom .tarion-cpg__main-container svg .hide.bedroom {
  display: none;
}

#tarion-cpg__main.bedroom .tarion-cpg__sidebar-container a.bedroom, #tarion-cpg__main.bedroom .tarion-cpg__sidebar-container p.bedroom {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.bathroom .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.bathroom .tarion-cpg__main-container svg .hide.bathroom {
  display: none;
}

#tarion-cpg__main.bathroom .tarion-cpg__sidebar-container a.bathroom, #tarion-cpg__main.bathroom .tarion-cpg__sidebar-container p.bathroom {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.hallway .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.hallway .tarion-cpg__main-container svg .hide.hallway {
  display: none;
}

#tarion-cpg__main.hallway .tarion-cpg__sidebar-container a.hallway, #tarion-cpg__main.hallway .tarion-cpg__sidebar-container p.hallway {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.attic .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.attic .tarion-cpg__main-container svg .hide.attic {
  display: none;
}

#tarion-cpg__main.attic .tarion-cpg__sidebar-container a.attic, #tarion-cpg__main.attic .tarion-cpg__sidebar-container p.attic {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.garage .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.garage .tarion-cpg__main-container svg .hide.garage {
  display: none;
}

#tarion-cpg__main.garage .tarion-cpg__sidebar-container a.garage, #tarion-cpg__main.garage .tarion-cpg__sidebar-container p.garage {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.living_room .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.living_room .tarion-cpg__main-container svg .hide.living_room {
  display: none;
}

#tarion-cpg__main.living_room .tarion-cpg__sidebar-container a.living_room, #tarion-cpg__main.living_room .tarion-cpg__sidebar-container p.living_room {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.kitchen .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.kitchen .tarion-cpg__main-container svg .hide.kitchen {
  display: none;
}

#tarion-cpg__main.kitchen .tarion-cpg__sidebar-container a.kitchen, #tarion-cpg__main.kitchen .tarion-cpg__sidebar-container p.kitchen {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.cold_room .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.cold_room .tarion-cpg__main-container svg .hide.cold_room {
  display: none;
}

#tarion-cpg__main.cold_room .tarion-cpg__sidebar-container a.cold_room, #tarion-cpg__main.cold_room .tarion-cpg__sidebar-container p.cold_room {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.basement_unfinished .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.basement_unfinished .tarion-cpg__main-container svg .hide.basement_unfinished {
  display: none;
}

#tarion-cpg__main.basement_unfinished .tarion-cpg__sidebar-container a.basement_unfinished, #tarion-cpg__main.basement_unfinished .tarion-cpg__sidebar-container p.basement_unfinished, #tarion-cpg__main.basement_unfinished .modal a.basement_unfinished {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.basement_finished .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.basement_finished .tarion-cpg__main-container svg .hide.basement_finished {
  display: none;
}

#tarion-cpg__main.basement_finished .tarion-cpg__sidebar-container a.basement_finished, #tarion-cpg__main.basement_finished .tarion-cpg__sidebar-container p.basement_finished, #tarion-cpg__main.basement_finished .modal a.basement_finished {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.laundry .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.laundry .tarion-cpg__main-container svg .hide.laundry {
  display: none;
}

#tarion-cpg__main.laundry .tarion-cpg__sidebar-container a.laundry, #tarion-cpg__main.laundry .tarion-cpg__sidebar-container p.laundry {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.climate_control .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.climate_control .tarion-cpg__main-container svg .hide.climate_control {
  display: none;
}

#tarion-cpg__main.climate_control .tarion-cpg__sidebar-container a.climate_control, #tarion-cpg__main.climate_control .tarion-cpg__sidebar-container p.climate_control {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.interior_doors .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.interior_doors .tarion-cpg__main-container svg .hide.interior_doors {
  display: none;
}

#tarion-cpg__main.interior_doors .tarion-cpg__sidebar-container a.interior_doors, #tarion-cpg__main.interior_doors .tarion-cpg__sidebar-container p.interior_doors, #tarion-cpg__main.interior_doors .modal a.interior_doors {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.trim .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.trim .tarion-cpg__main-container svg .hide.trim {
  display: none;
}

#tarion-cpg__main.trim .tarion-cpg__sidebar-container a.trim, #tarion-cpg__main.trim .tarion-cpg__sidebar-container p.trim {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.interior_finishes .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.interior_finishes .tarion-cpg__main-container svg .hide.interior_finishes {
  display: none;
}

#tarion-cpg__main.interior_finishes .tarion-cpg__sidebar-container a.interior_finishes, #tarion-cpg__main.interior_finishes .tarion-cpg__sidebar-container p.interior_finishes {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.flooring .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.flooring .tarion-cpg__main-container svg .hide.flooring {
  display: none;
}

#tarion-cpg__main.flooring .tarion-cpg__sidebar-container a.flooring, #tarion-cpg__main.flooring .tarion-cpg__sidebar-container p.flooring {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.conveying_systems .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.conveying_systems .tarion-cpg__main-container svg .hide.conveying_systems {
  display: none;
}

#tarion-cpg__main.conveying_systems .tarion-cpg__sidebar-container a.conveying_systems, #tarion-cpg__main.conveying_systems .tarion-cpg__sidebar-container p.conveying_systems {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.mechanical .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.mechanical .tarion-cpg__main-container svg .hide.mechanical {
  display: none;
}

#tarion-cpg__main.mechanical .tarion-cpg__sidebar-container a.mechanical, #tarion-cpg__main.mechanical .tarion-cpg__sidebar-container p.mechanical {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.ceiling .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.ceiling .tarion-cpg__main-container svg .hide.ceiling {
  display: none;
}

#tarion-cpg__main.ceiling .tarion-cpg__sidebar-container a.ceiling, #tarion-cpg__main.ceiling .tarion-cpg__sidebar-container p.ceiling {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.wall .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.wall .tarion-cpg__main-container svg .hide.wall {
  display: none;
}

#tarion-cpg__main.wall .tarion-cpg__sidebar-container a.wall, #tarion-cpg__main.wall .tarion-cpg__sidebar-container p.wall {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.windows .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.windows .tarion-cpg__main-container svg .hide.windows {
  display: none;
}

#tarion-cpg__main.windows .tarion-cpg__sidebar-container a.windows, #tarion-cpg__main.windows .tarion-cpg__sidebar-container p.windows {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.ce_windows .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.ce_windows .tarion-cpg__main-container svg .hide.ce_windows {
  display: none;
}

#tarion-cpg__main.ce_windows .tarion-cpg__sidebar-container a.ce_windows, #tarion-cpg__main.ce_windows .tarion-cpg__sidebar-container p.ce_windows {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.countertops .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.countertops .tarion-cpg__main-container svg .hide.countertops {
  display: none;
}

#tarion-cpg__main.countertops .tarion-cpg__sidebar-container a.countertops, #tarion-cpg__main.countertops .tarion-cpg__sidebar-container p.countertops {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.cabinets .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.cabinets .tarion-cpg__main-container svg .hide.cabinets {
  display: none;
}

#tarion-cpg__main.cabinets .tarion-cpg__sidebar-container a.cabinets, #tarion-cpg__main.cabinets .tarion-cpg__sidebar-container p.cabinets {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.plumbing .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.plumbing .tarion-cpg__main-container svg .hide.plumbing {
  display: none;
}

#tarion-cpg__main.plumbing .tarion-cpg__sidebar-container a.plumbing, #tarion-cpg__main.plumbing .tarion-cpg__sidebar-container p.plumbing {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.stairs .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.stairs .tarion-cpg__main-container svg .hide.stairs {
  display: none;
}

#tarion-cpg__main.stairs .tarion-cpg__sidebar-container a.stairs, #tarion-cpg__main.stairs .tarion-cpg__sidebar-container p.stairs {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.fireplace .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.fireplace .tarion-cpg__main-container svg .hide.fireplace {
  display: none;
}

#tarion-cpg__main.fireplace .tarion-cpg__sidebar-container a.fireplace, #tarion-cpg__main.fireplace .tarion-cpg__sidebar-container p.fireplace {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.cold_electrical .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.cold_electrical .tarion-cpg__main-container svg .hide.cold_electrical {
  display: none;
}

#tarion-cpg__main.cold_electrical .tarion-cpg__sidebar-container a.cold_electrical, #tarion-cpg__main.cold_electrical .tarion-cpg__sidebar-container p.cold_electrical {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.garage_floor .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.garage_floor .tarion-cpg__main-container svg .hide.garage_floor {
  display: none;
}

#tarion-cpg__main.garage_floor .tarion-cpg__sidebar-container a.garage_floor, #tarion-cpg__main.garage_floor .tarion-cpg__sidebar-container p.garage_floor {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.cold_room_door .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.cold_room_door .tarion-cpg__main-container svg .hide.cold_room_door {
  display: none;
}

#tarion-cpg__main.cold_room_door .tarion-cpg__sidebar-container a.cold_room_door, #tarion-cpg__main.cold_room_door .tarion-cpg__sidebar-container p.cold_room_door {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.unfinished_floor .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.unfinished_floor .tarion-cpg__main-container svg .hide.unfinished_floor {
  display: none;
}

#tarion-cpg__main.unfinished_floor .tarion-cpg__sidebar-container a.unfinished_floor, #tarion-cpg__main.unfinished_floor .tarion-cpg__sidebar-container p.unfinished_floor {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.finished_flooring .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.finished_flooring .tarion-cpg__main-container svg .hide.finished_flooring {
  display: none;
}

#tarion-cpg__main.finished_flooring .tarion-cpg__sidebar-container a.finished_flooring, #tarion-cpg__main.finished_flooring .tarion-cpg__sidebar-container p.finished_flooring {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.unfinished_wall .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.unfinished_wall .tarion-cpg__main-container svg .hide.unfinished_wall {
  display: none;
}

#tarion-cpg__main.unfinished_wall .tarion-cpg__sidebar-container a.unfinished_wall, #tarion-cpg__main.unfinished_wall .tarion-cpg__sidebar-container p.unfinished_wall {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.finished_wall .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.finished_wall .tarion-cpg__main-container svg .hide.finished_wall {
  display: none;
}

#tarion-cpg__main.finished_wall .tarion-cpg__sidebar-container a.finished_wall, #tarion-cpg__main.finished_wall .tarion-cpg__sidebar-container p.finished_wall {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.unfinished_plumbing .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.unfinished_plumbing .tarion-cpg__main-container svg .hide.unfinished_plumbing {
  display: none;
}

#tarion-cpg__main.unfinished_plumbing .tarion-cpg__sidebar-container a.unfinished_plumbing, #tarion-cpg__main.unfinished_plumbing .tarion-cpg__sidebar-container p.unfinished_plumbing {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.basement_finished_doors .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.basement_finished_doors .tarion-cpg__main-container svg .hide.basement_finished_doors {
  display: none;
}

#tarion-cpg__main.basement_finished_doors .tarion-cpg__sidebar-container a.basement_finished_doors, #tarion-cpg__main.basement_finished_doors .tarion-cpg__sidebar-container p.basement_finished_doors {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.entry .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.entry .tarion-cpg__main-container svg .hide.entry {
  display: none;
}

#tarion-cpg__main.entry .tarion-cpg__sidebar-container a.entry, #tarion-cpg__main.entry .tarion-cpg__sidebar-container p.entry {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.driveway .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.driveway .tarion-cpg__main-container svg .hide.driveway {
  display: none;
}

#tarion-cpg__main.driveway .tarion-cpg__sidebar-container a.driveway, #tarion-cpg__main.driveway .tarion-cpg__sidebar-container p.driveway {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.landscape .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.landscape .tarion-cpg__main-container svg .hide.landscape {
  display: none;
}

#tarion-cpg__main.landscape .tarion-cpg__sidebar-container a.landscape, #tarion-cpg__main.landscape .tarion-cpg__sidebar-container p.landscape {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.landscaping .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.landscaping .tarion-cpg__main-container svg .hide.landscaping {
  display: none;
}

#tarion-cpg__main.landscaping .tarion-cpg__sidebar-container a.landscaping, #tarion-cpg__main.landscaping .tarion-cpg__sidebar-container p.landscaping {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.decks .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.decks .tarion-cpg__main-container svg .hide.decks {
  display: none;
}

#tarion-cpg__main.decks .tarion-cpg__sidebar-container a.decks, #tarion-cpg__main.decks .tarion-cpg__sidebar-container p.decks {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.balcony .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.balcony .tarion-cpg__main-container svg .hide.balcony {
  display: none;
}

#tarion-cpg__main.balcony .tarion-cpg__sidebar-container a.balcony, #tarion-cpg__main.balcony .tarion-cpg__sidebar-container p.balcony {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.chimney .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.chimney .tarion-cpg__main-container svg .hide.chimney {
  display: none;
}

#tarion-cpg__main.chimney .tarion-cpg__sidebar-container a.chimney, #tarion-cpg__main.chimney .tarion-cpg__sidebar-container p.chimney {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.shingles .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.shingles .tarion-cpg__main-container svg .hide.shingles {
  display: none;
}

#tarion-cpg__main.shingles .tarion-cpg__sidebar-container a.shingles, #tarion-cpg__main.shingles .tarion-cpg__sidebar-container p.shingles {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.eavestroughs .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.eavestroughs .tarion-cpg__main-container svg .hide.eavestroughs {
  display: none;
}

#tarion-cpg__main.eavestroughs .tarion-cpg__sidebar-container a.eavestroughs, #tarion-cpg__main.eavestroughs .tarion-cpg__sidebar-container p.eavestroughs {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.skylight .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.skylight .tarion-cpg__main-container svg .hide.skylight {
  display: none;
}

#tarion-cpg__main.skylight .tarion-cpg__sidebar-container a.skylight, #tarion-cpg__main.skylight .tarion-cpg__sidebar-container p.skylight {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.roofs .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.roofs .tarion-cpg__main-container svg .hide.roofs {
  display: none;
}

#tarion-cpg__main.roofs .tarion-cpg__sidebar-container a.roofs, #tarion-cpg__main.roofs .tarion-cpg__sidebar-container p.roofs {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.exterior_paint .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.exterior_paint .tarion-cpg__main-container svg .hide.exterior_paint {
  display: none;
}

#tarion-cpg__main.exterior_paint .tarion-cpg__sidebar-container a.exterior_paint, #tarion-cpg__main.exterior_paint .tarion-cpg__sidebar-container p.exterior_paint {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.amenity_room .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.amenity_room .tarion-cpg__main-container svg .hide.amenity_room {
  display: none;
}

#tarion-cpg__main.amenity_room .tarion-cpg__sidebar-container a.amenity_room, #tarion-cpg__main.amenity_room .tarion-cpg__sidebar-container p.amenity_room {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.garage_exterior_door .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.garage_exterior_door .tarion-cpg__main-container svg .hide.garage_exterior_door {
  display: none;
}

#tarion-cpg__main.garage_exterior_door .tarion-cpg__sidebar-container a.garage_exterior_door, #tarion-cpg__main.garage_exterior_door .tarion-cpg__sidebar-container p.garage_exterior_door {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.ground_lobby .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.ground_lobby .tarion-cpg__main-container svg .hide.ground_lobby {
  display: none;
}

#tarion-cpg__main.ground_lobby .tarion-cpg__sidebar-container a.ground_lobby, #tarion-cpg__main.ground_lobby .tarion-cpg__sidebar-container p.ground_lobby {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.building_exterior .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.building_exterior .tarion-cpg__main-container svg .hide.building_exterior {
  display: none;
}

#tarion-cpg__main.building_exterior .tarion-cpg__sidebar-container a.building_exterior, #tarion-cpg__main.building_exterior .tarion-cpg__sidebar-container p.building_exterior {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.parking_garage .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.parking_garage .tarion-cpg__main-container svg .hide.parking_garage {
  display: none;
}

#tarion-cpg__main.parking_garage .tarion-cpg__sidebar-container a.parking_garage, #tarion-cpg__main.parking_garage .tarion-cpg__sidebar-container p.parking_garage {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.garbage_recycling .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.garbage_recycling .tarion-cpg__main-container svg .hide.garbage_recycling {
  display: none;
}

#tarion-cpg__main.garbage_recycling .tarion-cpg__sidebar-container a.garbage_recycling, #tarion-cpg__main.garbage_recycling .tarion-cpg__sidebar-container p.garbage_recycling {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.pool_area .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.pool_area .tarion-cpg__main-container svg .hide.pool_area {
  display: none;
}

#tarion-cpg__main.pool_area .tarion-cpg__sidebar-container a.pool_area, #tarion-cpg__main.pool_area .tarion-cpg__sidebar-container p.pool_area {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.corridors .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.corridors .tarion-cpg__main-container svg .hide.corridors {
  display: none;
}

#tarion-cpg__main.corridors .tarion-cpg__sidebar-container a.corridors, #tarion-cpg__main.corridors .tarion-cpg__sidebar-container p.corridors {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.exit_stairs .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.exit_stairs .tarion-cpg__main-container svg .hide.exit_stairs {
  display: none;
}

#tarion-cpg__main.exit_stairs .tarion-cpg__sidebar-container a.exit_stairs, #tarion-cpg__main.exit_stairs .tarion-cpg__sidebar-container p.exit_stairs {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.elevators .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.elevators .tarion-cpg__main-container svg .hide.elevators {
  display: none;
}

#tarion-cpg__main.elevators .tarion-cpg__sidebar-container a.elevators, #tarion-cpg__main.elevators .tarion-cpg__sidebar-container p.elevators {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.mechanical_room .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.mechanical_room .tarion-cpg__main-container svg .hide.mechanical_room {
  display: none;
}

#tarion-cpg__main.mechanical_room .tarion-cpg__sidebar-container a.mechanical_room, #tarion-cpg__main.mechanical_room .tarion-cpg__sidebar-container p.mechanical_room {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.barrier_free .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.barrier_free .tarion-cpg__main-container svg .hide.barrier_free {
  display: none;
}

#tarion-cpg__main.barrier_free .tarion-cpg__sidebar-container a.barrier_free, #tarion-cpg__main.barrier_free .tarion-cpg__sidebar-container p.barrier_free {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.parking_painting .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.parking_painting .tarion-cpg__main-container svg .hide.parking_painting {
  display: none;
}

#tarion-cpg__main.parking_painting .tarion-cpg__sidebar-container a.parking_painting, #tarion-cpg__main.parking_painting .tarion-cpg__sidebar-container p.parking_painting {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.garabage_recycling .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.garabage_recycling .tarion-cpg__main-container svg .hide.garabage_recycling {
  display: none;
}

#tarion-cpg__main.garabage_recycling .tarion-cpg__sidebar-container a.garabage_recycling, #tarion-cpg__main.garabage_recycling .tarion-cpg__sidebar-container p.garabage_recycling {
  color: #fff;
  background: #4f87b2;
}

#tarion-cpg__main.exterior_closure .tarion-cpg__main-container svg .hide {
  display: block;
}

#tarion-cpg__main.exterior_closure .tarion-cpg__main-container svg .hide.exterior_closure {
  display: none;
}

#tarion-cpg__main.exterior_closure .tarion-cpg__sidebar-container a.exterior_closure, #tarion-cpg__main.exterior_closure .tarion-cpg__sidebar-container p.exterior_closure {
  color: #fff;
  background: #4f87b2;
}

.tarion-cpg__back-btn {
  background: #dce4ee;
}

@media screen and (width <= 980px) {
  .tarion-cpg__back-btn {
    display: none;
  }
}

ul.tarion-cpg__breadcrumb-trail {
  margin: 0 1rem;
  padding: 0;
  list-style: none;
  display: inline-block;
}

ul.tarion-cpg__breadcrumb-trail > li {
  text-transform: uppercase;
  font-size: .875rem;
  display: inline-block;
}

@media screen and (width <= 980px) {
  ul.tarion-cpg__breadcrumb-trail > li {
    display: none;
  }
}

ul.tarion-cpg__breadcrumb-trail > li a {
  color: #fffefe;
  font-size: 20px;
  text-decoration: none;
  transition: color .1s linear;
}

ul.tarion-cpg__breadcrumb-trail > li a:hover {
  text-decoration: underline;
}

ul.tarion-cpg__breadcrumb-trail > li:last-of-type {
  font-weight: 700;
}

ul.tarion-cpg__breadcrumb-trail > li:last-of-type a {
  color: #fffefe;
}

ul.tarion-cpg__breadcrumb-trail > li:last-of-type a:hover {
  text-decoration: underline;
}

ul.tarion-cpg__breadcrumb-trail > li:after {
  content: ":";
  color: #868686;
  padding: 0 .25em;
}

ul.tarion-cpg__breadcrumb-trail > li:last-of-type {
  display: inline-block;
}

ul.tarion-cpg__breadcrumb-trail > li:last-of-type:after {
  content: "";
  padding: 0;
}

ul.tarion-cpg__breadcrumb-trail li.left_Bread {
  position: absolute;
  left: 30px;
}

ul.tarion-cpg__breadcrumb-trail li.left_Bread:after {
  content: "";
}

@media screen and (width <= 980px) {
  ul.tarion-cpg__breadcrumb-trail li.left_Bread {
    display: none;
  }
}

@media screen and (width <= 700px) {
  ul.tarion-cpg__breadcrumb-trail {
    text-align: center;
    width: 100%;
    margin: 0;
    position: absolute;
    top: 46px;
    left: 0;
  }
}

#tarion-cpg__header {
  background-color: #fffefe80;
  align-items: center;
  padding-bottom: 0;
}

#tarion-cpg__header .header_crumb_search .tarion-cpg__back-btn {
  box-shadow: none;
  background: none;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 8px;
  left: 30px;
}

#tarion-cpg__header .header_crumb_search .tarion-cpg__back-btn svg {
  fill: #fff;
}

@media screen and (width >= 981px) {
  #tarion-cpg__header .header_crumb_search .tarion-cpg__back-btn {
    display: none;
  }
}

#tarion-cpg__header h1 {
  font-size: 2.5rem;
}

#tarion-cpg__header #h2 {
  font-size: 2rem;
}

#tarion-cpg__header div.header_logo {
  background: #fff;
  margin-left: 2rem;
  display: block;
}

@media screen and (width <= 980px) {
  #tarion-cpg__header div.header_logo {
    margin-top: 8px;
    margin-left: 8px;
  }
}

@media screen and (width <= 600px) {
  #tarion-cpg__header div.header_logo {
    text-align: center;
    width: 100%;
  }

  #tarion-cpg__header div.header_logo svg {
    width: auto;
    height: 30px;
  }

  #tarion-cpg__header div.header_logo h1 {
    font-size: 1.5rem;
  }
}

#tarion-cpg__header div.header_logo h1, #tarion-cpg__header div.header_logo #h2 {
  color: #306398;
  margin: 0 0 0 10px;
  padding-bottom: 4px;
  display: inline-block;
}

#tarion-cpg__header div.header_logo #h2 {
  margin-left: 0;
  display: block;
}

@media screen and (width <= 980px) {
  #tarion-cpg__header div.header_logo #h2 {
    display: none;
  }
}

@media screen and (width <= 1282px) {
  #tarion-cpg__header h1 {
    font-size: 2rem;
  }

  #tarion-cpg__header #h2 {
    font-size: 28px;
  }
}

.tarion-cpg__nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tarion-cpg__nav ul li {
  position: relative;
}

.tarion-cpg__nav {
  border: 2px solid #393939;
}

.tarion-cpg__nav ul > li > a, .tarion-cpg__nav ul > li > p, .modal > p > a {
  color: #393939;
  text-transform: uppercase;
  letter-spacing: .5px;
  border-bottom: 2px solid #393939;
  padding: .75rem 1rem;
  font-weight: bold;
  text-decoration: none;
  transition: background-color .1s linear, color .1s linear;
  display: block;
}

.tarion-cpg__nav ul > li > a:hover, .tarion-cpg__nav ul > li > p:hover, .modal > p > a:hover {
  color: #fff;
  background-color: #4f87b2;
}

.tarion-cpg__nav ul > li > a:active, .tarion-cpg__nav ul > li > p:active, .modal > p > a:active {
  color: #fffefe;
  background-color: #393939;
}

.tarion-cpg__nav ul > li > p {
  cursor: pointer;
  margin: 0;
}

.tarion-cpg__nav ul > li:last-of-type > p, .tarion-cpg__nav ul > li:last-child a, .modal > p:last-child > a {
  border: none;
}

.arrows {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 10px;
  background: none !important;
}

.arrows path {
  stroke: #000;
  stroke-width: 2px;
}

.arrows.arrows2 {
  top: 0;
}

.modal {
  z-index: 10000;
  color: #393939;
  text-transform: uppercase;
  letter-spacing: .5px;
  background: #eee;
  border: 2px solid #393939;
  border-top: none;
  width: 200px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color .1s linear, color .1s linear;
  display: none;
  position: fixed;
  top: -1000px;
  left: -1000px;
}

.modal p {
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: relative;
}

.modal p:hover {
  background-color: #eee;
}

.modal p:active {
  color: #fffefe;
  background-color: #393939;
}

.modal p:last-child {
  border: none;
}

.modal p:first-child {
  border-top: 2px solid #393939;
}

.p_link {
  height: 18px;
  font-size: 15px;
  display: inline-block;
}

#tarion-cpg__not-found {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

#tarion-cpg__not-found > h2 {
  font-size: 2.5rem;
}

#tarion-cpg__not-found .tarion-cpg__search-form {
  background-color: #fffefe;
  padding: 1rem;
  font-size: 1.5em;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
}

#tarion-cpg__search {
  width: 100%;
  height: 100%;
}

#tarion-cpg__search-form, #tarion-cpg__search-results {
  width: 100%;
  max-width: 600px;
  margin: 2rem auto;
}

#tarion-cpg__search-results {
  background-color: #fffefe;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
}

#tarion-cpg__search-results-header, #tarion-cpg__search-results-main {
  padding: 1rem;
}

#tarion-cpg__search-results-header {
  align-items: center;
  display: flex;
}

#tarion-cpg__search-results-header h2 {
  flex: 1;
  margin: 0;
}

#tarion-cpg__search-form .tarion-cpg__search-form {
  background-color: #fffefe;
  margin: 0;
  padding: 1rem;
  font-size: 1.5em;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
}

@media screen and (width <= 980px) {
  form .tarion-cpg__search-input {
    text-align: left;
    height: 36px;
    padding-left: 4px;
  }
}

.articleContainer.search h2 {
  display: inline;
}

.article_back_button.search {
  text-align: center;
}

.article_back_button.search button {
  float: left;
  display: inline-block;
}

@media screen and (width <= 980px) {
  .article_back_button.search button {
    display: none;
  }
}

.mobile_show {
  display: none;
}

@media screen and (width <= 980px) {
  .mobile_show {
    display: inline-block;
  }
}

.tarion-cpg__nav ul > li > a.mobile_show {
  display: none;
}

@media screen and (width <= 980px) {
  .tarion-cpg__nav ul > li > a.mobile_show {
    display: block;
  }

  .mobile_hide {
    display: none !important;
  }
}

.mobile_show.submit {
  box-sizing: border-box;
  border-left: 1px solid #000;
  height: 36px;
  position: absolute;
  top: 0;
  right: 0;
}

.tarion-cpg__search-form {
  align-items: center;
  margin: .5rem 1rem;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

.tarion-cpg__search-input {
  text-align: center;
  border: none;
  outline: none;
  flex: 1;
  margin-right: .5em;
  padding: .5rem 0;
  font-size: 1em;
  transition: border-color .1s linear;
  display: block;
}

@media screen and (width <= 980px) {
  .tarion-cpg__search-input {
    box-sizing: border-box;
    width: calc(100% - 36px);
  }
}

.tarion-cpg__search-input:focus {
  border-bottom-color: #393939;
}

.tarion-cpg__sidebar {
  background-color: #fffefe;
}

.tarion-cpg__sidebar .tarion-cpg__search-form {
  margin: 1rem;
}

.tarion-cpg__with-sidebar .tarion-cpg__sidebar-header {
  margin: 6px 0 14px;
  padding-left: 2px;
  font-size: 1.5rem;
}

.tarion-cpg__with-sidebar .tarion-cpg__sidebar {
  min-width: 280px;
  max-width: 318px;
  max-height: calc(100% - 165px);
  display: inline-block;
  overflow: auto;
}

@media screen and (width <= 980px) {
  .tarion-cpg__with-sidebar .tarion-cpg__sidebar {
    width: 100%;
    max-width: 100%;
  }
}

.articleContainer {
  background: #f1f1f1;
  height: calc(100% + 15px);
  position: relative;
}

@media screen and (width <= 980px) {
  .articleContainer {
    height: auto;
  }
}

.articleContainer .prev_next {
  justify-content: space-between;
  width: 90%;
  margin: 10px auto 0;
  padding-bottom: 8px;
  display: flex;
}

@media screen and (width >= 980px) {
  .articleContainer .prev_next {
    display: none;
  }
}

.articleContainer .prev_next div {
  color: #27588d;
  flex: 0 50%;
  margin: 0;
  display: flex;
}

.articleContainer .prev_next div p {
  cursor: pointer;
  margin: 2px 0 0;
  padding-left: 5px;
  display: inline-block;
}

.articleContainer .prev_next div span {
  cursor: pointer;
  font-size: 30px;
  line-height: 20px;
}

.articleContainer .prev_next div:nth-child(2) {
  justify-content: flex-end;
}

.articleContainer .prev_next div:nth-child(2) p {
  padding-left: 0;
  padding-right: 5px;
}

.article_sidebar {
  vertical-align: top;
  background: #fffefe;
  width: 300px;
  height: calc(100% - 75px);
  margin-top: 10px;
  padding-top: 18px;
  display: inline-block;
  overflow-y: auto;
}

.article_sidebar .link {
  color: #224e85;
  cursor: pointer;
  border-bottom: 2px solid #ddd;
  width: 260px;
  margin: 0 auto;
  padding-top: 9px;
  padding-bottom: 9px;
}

.article_sidebar .link.active {
  background: #eee;
}

.article_sidebar .link:hover {
  text-decoration: underline;
}

.article_sidebar .link span {
  font-weight: bold;
}

.article_sidebar .link:first-child {
  border-top: 2px solid #ddd;
}

@media screen and (width <= 980px) {
  .article_sidebar {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}

.article_sidebar.mobile_visible {
  display: block;
  overflow: scroll;
}

.mobile_article_sidebar {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

@media screen and (width <= 980px) {
  .mobile_article_sidebar {
    display: block;
  }
}

.article_data {
  vertical-align: top;
  background: #fffefe;
  width: calc(100% - 330px);
  height: calc(100% - 65px);
  margin: 10px 0 10px 10px;
  padding-left: 20px;
  display: inline-block;
  overflow-y: auto;
}

@media screen and (width <= 980px) {
  .article_data {
    height: auto;
  }
}

.article_data h3 {
  color: #27588d;
  margin-top: 5px;
  margin-bottom: 10px;
  padding-top: 12px;
  font-size: 28px;
  font-weight: 600;
}

.article_data h4 {
  color: #27588d;
  margin-top: 0;
  margin-bottom: 10px;
  padding-top: 0;
  font-size: 24px;
  font-weight: 500;
}

.article_data p {
  color: #000;
  word-wrap: break-word;
  margin: 20px 0 0;
  font-size: 15px;
}

.article_data p span {
  color: #27588d;
  padding-bottom: 4px;
  font-size: 24px;
  font-weight: 500;
  display: block;
}

@media screen and (width <= 980px) {
  .article_data {
    margin: 10px 0 0;
  }
}

.article_data .article_info {
  vertical-align: top;
  border-right: 10px solid #eee;
  width: calc(100% - 322px);
  padding-right: 6px;
  display: inline-block;
}

.article_data .article_info.height_100 {
  height: 100%;
}

.article_data .article_info.appendix {
  border: none;
  width: calc(100% - 12px);
}

@media screen and (width <= 980px) {
  .article_data .article_info {
    border: none;
    width: 90%;
    display: block;
  }

  .article_data .article_info.height_100 {
    height: auto;
  }
}

.article_data .article_pics {
  vertical-align: top;
  color: #000;
  width: 300px;
  padding-left: 6px;
  display: inline-block;
}

.article_data .article_pics div.images, .article_data .article_pics div.related, .article_data .article_pics div.videos, .article_data .article_pics div.disclaimer {
  width: 294px;
}

@media screen and (width <= 980px) {
  .article_data .article_pics div.images, .article_data .article_pics div.related, .article_data .article_pics div.videos, .article_data .article_pics div.disclaimer {
    width: 100%;
  }

  .article_data .article_pics div.disclaimer {
    text-align: center;
    margin-top: 12px;
  }
}

.article_data .article_pics div.disclaimer div span.more_link {
  cursor: pointer;
  margin-left: 4px;
  display: inline-block;
}

.article_data .article_pics div.disclaimer div span.more_link:hover {
  color: red;
}

.article_data .article_pics div.disclaimer div span {
  font-size: inherit;
}

.article_data .article_pics div.images img {
  width: 100%;
  margin-top: 6px;
}

.article_data .article_pics img {
  width: 300px;
}

.article_data .article_pics p {
  margin: 0;
}

.article_data .article_pics p.relatedHeader {
  margin-bottom: 8px;
}

.article_data .article_pics p.link {
  color: #224e85;
  cursor: pointer;
  width: 290px;
  margin: 0;
  padding-bottom: 8px;
}

@media screen and (width <= 980px) {
  .article_data .article_pics p.link {
    width: auto;
  }
}

.article_data .article_pics p.link:hover {
  text-decoration: underline;
}

.article_data .article_pics .pics_header {
  color: #fffefe;
  text-align: center;
  background: #306398;
  height: 20px;
  padding: 6px 2px;
}

.article_data .article_pics div.related, .article_data .article_pics div.videos {
  padding-top: 25px;
}

.article_data .article_pics div p {
  color: #000;
  font-weight: bold;
}

@media screen and (width <= 980px) {
  .article_data .article_pics {
    width: 99%;
    display: block;
  }

  .article_data .article_pics div.images, .article_data .article_pics div.videos {
    width: 50%;
    margin: 0 auto;
  }

  .article_data .article_pics div.images {
    margin-top: 10px;
  }

  .article_data .article_pics div.images img {
    margin: 0;
  }
}

.article_data .img {
  width: 100%;
}

@media screen and (width <= 980px) {
  .article_data {
    width: 90%;
    margin: 0 auto;
    padding: 12px;
    display: block;
    overflow: hidden;
  }
}

.appendix figure {
  width: 50%;
  margin: 0 auto;
}

.appendix h5 {
  margin-bottom: 8px;
  font-size: 18px;
}

.appendix .appendix_table {
  text-align: center;
  margin-top: 12px;
  padding: 8px;
  overflow-x: auto;
}

.appendix .appendix_table h4 {
  margin-top: 8px;
  margin-bottom: 0;
}

.appendix .appendix_table h5 {
  text-align: right;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
}

.appendix .appendix_table table {
  border-collapse: collapse;
  width: 100%;
  min-width: 900px;
}

.appendix .appendix_table table thead {
  text-align: left;
  font-size: 14px;
}

.appendix .appendix_table table td.shaded {
  background: #c1c1c1;
}

.appendix .appendix_table.a2 {
  border: 1px solid #000;
}

.appendix .appendix_table.a2 table tr:last-child {
  font-size: 14px;
}

.appendix .appendix_table.borders table, .appendix .appendix_table.borders table td {
  border: 1px solid #000;
}

.appendix .appendix_table.borders table td.doubled_border {
  border-style: double;
  border-width: 3px;
}

.appendix .appendix_table p {
  text-align: left;
}

.appendix .appendix_table .shaded_red_fading td {
  background: #fabf8f;
}

.appendix .appendix_table .shaded_red_fading td:nth-child(2) {
  background: #fac599;
}

.appendix .appendix_table .shaded_red_fading td:nth-child(3) {
  background: #fbcaa3;
}

.appendix .appendix_table .shaded_red_fading td:nth-child(4) {
  background: #fbd0ac;
}

.appendix .appendix_table .shaded_red_fading td:nth-child(5) {
  background: #fcd5b6;
}

.appendix .appendix_table .shaded_red_fading td:nth-child(6) {
  background: #fcdbc0;
}

.appendix .appendix_table .shaded_red_fading td:nth-child(7) {
  background: #fde0ca;
}

.appendix .appendix_table .shaded_blue_fading td {
  background: #92cddc;
}

.appendix .appendix_table .shaded_blue_fading td:nth-child(2) {
  background: #9ed2e0;
}

.appendix .appendix_table .shaded_blue_fading td:nth-child(3) {
  background: #a9d8e3;
}

.appendix .appendix_table .shaded_blue_fading td:nth-child(4) {
  background: #b5dde7;
}

.appendix .appendix_table .shaded_blue_fading td:nth-child(5) {
  background: #c0e2eb;
}

.appendix .appendix_table .shaded_blue_fading td:nth-child(6) {
  background: #cce8ef;
}

.appendix .appendix_table .shaded_blue_fading td:nth-child(7) {
  background: #d7edf2;
}

.appendix.a4 #last_p_article_info p {
  margin-top: 0;
  font-size: 14px;
}

.article_loading {
  text-align: center;
  vertical-align: top;
  background: #fffefe;
  width: calc(100% - 340px);
  height: calc(100% - 25px);
  margin: 10px 0 20px 20px;
  padding-left: 20px;
  display: inline-block;
  overflow-y: auto;
}

.article_loading img {
  height: 200px;
}

.slick-prev {
  z-index: 2;
  left: 0;
}

.slick-next {
  right: 0;
}

.article_back_button {
  height: 40px;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  padding-top: 10px;
  padding-left: 20px;
}

.center {
  text-align: center;
}

.loading_img {
  height: 120px;
}

.menu_breadcrumb line {
  stroke: #000;
  stroke-width: 3px;
}

.bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}

p.no_margin {
  margin: 0;
}

p.small {
  font-size: 14px;
}

@media screen and (width <= 425px) {
  .mobile_article_sidebar.search {
    text-align: right;
    position: relative;
    top: 0;
  }
}

.tarion_cpg_categories {
  flex-flow: wrap;
  justify-content: center;
  margin-top: 10px;
  display: flex;
}

.tarion_cpg_categories .category_detail {
  text-align: center;
  flex: 0 0 33%;
  justify-content: center;
  max-width: 400px;
  min-height: 200px;
  margin-bottom: 20px;
  text-decoration: none;
  display: inline-flex;
}

.tarion_cpg_categories .category_detail div {
  min-height: inherit;
  display: inherit;
  align-items: center;
  justify-content: inherit;
  background: #adc2e4;
  border-radius: 5px;
  flex-flow: wrap;
  width: 50%;
  margin: 0 auto;
}

.tarion_cpg_categories .category_detail div svg {
  flex: 100%;
}

.tarion_cpg_categories .category_detail div p {
  color: #fff;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
}

.tarion_cpg_categories .category_detail:hover p {
  color: #000;
}

.tarion_cpg_categories .category_detail:hover img {
  box-shadow: -5px 6px 10px #fbb03b;
}

@media screen and (width <= 980px) {
  .tarion_cpg_categories .category_detail {
    text-align: center;
    width: 90%;
    margin: 0 auto 20px;
    display: flex;
  }
}

@media screen and (width <= 840px) {
  .tarion_cpg_categories .category_detail div {
    width: 90%;
  }

  .tarion_cpg_categories .category_detail {
    flex: 50%;
  }
}

.tarion-cpg-category_container {
  position: relative;
}

.category_back_button {
  margin: 5px 0 0;
  position: absolute;
  top: 0;
  left: 10px;
}

.category_header {
  text-align: center;
}

@media screen and (width <= 980px) {
  .category_header {
    margin-top: 43px;
  }
}

@media print {
  .header_crumb_search, #tarion-cpg__header #h2, .tarion-cpg__back-btn, .article_sidebar, .article_pics {
    display: none !important;
  }

  .articleContainer, .article_info, .article_data {
    overflow: hidden !important;
  }

  .article_info {
    border-right: none !important;
    width: 100% !important;
    display: block !important;
  }

  .article_data, .tarion-cpg__main-container {
    width: 100% !important;
    display: block !important;
  }

  #tarion-cpg__header h1, .tarion-cpg__sidebar-container, .tarion-cpg__svg-container {
    display: block !important;
  }
}

/*# sourceMappingURL=index.7eecdaa0.css.map */
