/**
 * Screen-reader only
 *
 * Visiually hides content while retaining it in the DOM for accessibilty.
 * @see http://a11yproject.com/posts/how-to-hide-content/
 */
 .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}