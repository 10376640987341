.tarion-cpg__nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tarion-cpg__nav ul li{
  position: relative;
}

.tarion-cpg__nav{
  border: 2px solid $grey-dark;
}

.tarion-cpg__nav ul > li > a, .tarion-cpg__nav ul > li > p, .modal > p > a {
  display: block;
  padding: .75rem 1rem;
  border-bottom: 2px solid $grey-dark;
  color: $grey-dark;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: .5px;
  transition:
    background-color 100ms linear,
    color 100ms linear;

  &:hover {
    background-color: #4f87b2;
    color:white;
  }

  &:active {
    color: $white;
    background-color: $grey-dark;
  }
}

.tarion-cpg__nav ul > li > p{  
  margin: 0px;
  cursor: pointer;
}

.tarion-cpg__nav ul > li:last-of-type > p{  
  border:none;
}

.tarion-cpg__nav ul > li:last-child a, .modal > p:last-child > a{
    border:none;
}

.arrows{
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
  background: transparent !important;

  & path{
    stroke: black;
	  stroke-width: 2px;
  }
}

.arrows.arrows2{
  top:0px;
}

.modal{
  display:none;
  position: fixed;
  top: -1000px;
  left: -1000px;
  width: 200px;
  z-index: 10000;
  background: $grey-light;
  border: 2px solid $grey-dark;
  border-top: none;
  color: $grey-dark;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: .5px;
  transition: background-color 100ms linear, color 100ms linear;

  & p{
    position: relative;
    padding: 0px;
    margin: 0px;
    cursor:pointer;

    &:hover {
      background-color: $grey-light;
    }

    &:active {
      color: $white;
      background-color: $grey-dark;
    }
  }

  & p:last-child{
    border:none;
  }

  & p:first-child{
    border-top: 2px solid $grey-dark;
  }
}

.p_link{
  display: inline-block;
  height: 18px;
  font-size: 15px;
}