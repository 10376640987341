@media print{
    .header_crumb_search,
    #tarion-cpg__header #h2,
    .tarion-cpg__back-btn,
    .article_sidebar,
    .article_pics{
        display:none !important;
    }

    .articleContainer,
    .article_info,
    .article_data{
        overflow: hidden !important;
    }

    .article_info{
        display:block !important;
        width: 100% !important;
        border-right: none !important;
    }

    .article_data,
    .tarion-cpg__main-container{
        width: 100% !important;
        display:block !important;
        
    }

    #tarion-cpg__header h1,
    .tarion-cpg__sidebar-container,
    .tarion-cpg__svg-container{
        display: block !important;
    }
}

