.tarion-cpg__search-form {
  display: inline-block;
  align-items: center;
  margin: .5rem 1rem;
  position: absolute;
  top: 0px;
  right: 0px;
}

.tarion-cpg__search-form > label {
  @extend .sr-only;
}

.tarion-cpg__search-input {
  flex: 1;
  display: block;
  margin-right: .5em;
  padding: .5rem 0;
  font-size: 1em;
  border: none;
  outline: none;
  transition: border-color 100ms linear;
  text-align: center;

  @media screen and (max-width: 980px){
      width: calc((100%) - (36px));
      box-sizing: border-box;
  }

  &:focus {
    border-bottom-color: $grey-dark;
  }
}

.tarion-cpg__search-btn {
  @extend .btn--icon-only;
}