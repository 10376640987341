#tarion-cpg__not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#tarion-cpg__not-found > h2 {
  font-size: $font-size-h1;
}

#tarion-cpg__not-found .tarion-cpg__search-form {
  padding: $padding-default;
  font-size: 1.5em;
  background-color: $white;
  box-shadow: $box-shadow-default;
}