.tarion-cpg__sidebar {
  background-color: $white;

}

.tarion-cpg__sidebar .tarion-cpg__search-form {
  margin: 1rem;
}

.tarion-cpg__with-sidebar .tarion-cpg__sidebar-header {
  font-size: $font-size-h4;
  margin: 14px 0px;
  margin-top:6px;
  padding-left: 2px;
}

.tarion-cpg__with-sidebar .tarion-cpg__sidebar {
  display: inline-block;
  min-width: 280px;
  max-width: 318px;
  max-height: calc(100% - 165px);
  overflow: auto;

  @media screen and (max-width: $mobile-size){
      max-width: 100%;
      width: 100%;
  }
}

