.articleContainer{
    height: calc(100% + 15px);
    background: $background;
    position: relative;

    @media screen and (max-width: 980px)
    {
        height: auto;//calc(83%);
    }

    .prev_next{
        width: 100%;
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;
        margin-top: 10px;
        padding-bottom: 8px;

        @media screen and (min-width: 980px){
            display:none;
        }

        div{
            flex: 0 1 50%;
            margin: 0px;
            color: $article-blue;
            display: flex;

            p{
                display: inline-block;
                margin: 0px;
                margin-top: 2px;
                cursor: pointer;
                padding-left: 5px;
            }

            span{
                font-size: 30px;
                line-height: 20px;
                cursor: pointer;
            }

            &:nth-child(2){
                justify-content: flex-end;

                p{
                    padding-left: 0px;
                    padding-right: 5px;
                }
            }
        }
    }
}

.article_sidebar{
    display:inline-block;
    background: $white;
    width: 300px;
    margin-top: 10px;
    padding-top: 18px;
    vertical-align: top;
    height: calc(100% - 75px);
    overflow-y: auto;

    & .link{
        margin: 0px auto;
        width: 260px;
        color: $text-blue;
        cursor: pointer;       
        padding-bottom: 9px;
        padding-top: 9px;
        border-bottom: 2px solid #ddd;

        &.active{
            background: #eee;
        }

        &:hover{
            text-decoration: underline;
        }

        & span{
            font-weight: bold;
        }
    }

    & .link:first-child{
        border-top: 2px solid #ddd;
    }

    @media screen and (max-width: 980px){
        display:none;
        position: absolute;
        top:0px;
        left: 0px;
        overflow: hidden;
    }

    &.mobile_visible{
        display:block;
        overflow: scroll;
    }
}

.mobile_article_sidebar{
    display:none;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    @media screen and (max-width: 980px){
        display:block;
    }
}

.article_data{
    display: inline-block;
    width: calc(100% - 330px);
    vertical-align: top;
    margin: 10px;
    background: $white;
    padding-left: 20px;
    height: calc(100% - 65px) ;
    overflow-y: auto;
    margin-right: 0px;

    @media screen and (max-width: 980px){
        height: auto;
    }

    & h3{
        color:$article-blue;
        font-size: 28px;
        margin-top: 5px;
        padding-top: 12px;
        margin-bottom: 10px;
        font-weight: 600;
    }

    & h4{
        color:$article-blue;
        font-size: 24px;
        margin-top: 0px;
        padding-top: 0px;
        margin-bottom: 10px;
        font-weight: 500;
    }

    & p{
        margin: 0px;
        color: $black;
        margin-top: 20px;
        font-size: 15px;
        word-wrap: break-word;

        & span{
            color: $article-blue;
            font-size: 24px;
            display:block;
            padding-bottom: 4px;
            font-weight: 500;
        }
    }

    @media screen and (max-width: 980px){
        margin: 0px;
        margin-top: 10px;
    }

    & .article_info{
        width: calc(100% - 300px - 12px - 10px);
        display: inline-block;
        vertical-align: top;
        border-right: 10px solid #eee;
        padding-right: 6px;

        &.height_100{
            height: 100%;
        }

        &.appendix{
            width: calc(100% - 12px);
            border: none;
        }

        @media screen and (max-width: 980px){
            display:block;
            width: 90%;
            border: none;

            &.height_100{
                height: auto;
            }
        }
    }

    & .article_pics{
        width: 300px;
        display: inline-block;
        vertical-align: top;
        color: $black;
        padding-left: 6px;

        & div.images, div.related, div.videos, div.disclaimer{
            width: calc((300px) - (6px));

            @media screen and (max-width: 980px){
                width: 100%;
            }
        }

        div.disclaimer{
            @media screen and (max-width: 980px){
                margin-top: 12px;
                text-align: center;
            }

            div{
                span.more_link{
                    display: inline-block;
                    margin-left: 4px;
                    cursor: pointer;

                    &:hover{
                        color:red;
                    }
                }

                span{
                    font-size: inherit;
                }
            }
        }

        & div.images img{
            width: 100%;
            margin-top: 6px;
        }

        & img{
            width: 300px;
        }

        & p{
            margin: 0px;

            &.relatedHeader{
                margin-bottom: 8px;
            }

            &.link{
                margin: 0px;
                width: 290px;
                margin-left: 0px;
                color: $text-blue;
                cursor: pointer;
                padding-bottom: 8px;

                @media screen and (max-width: 980px){
                    width: auto;
                }

                &:hover{
                    text-decoration: underline;
                }
            }
        }

        & .pics_header{
            background: $header-blue;
            color: $white;
            padding: 6px 2px;
            text-align: center;
            height: 20px;
        }

        & div.related, & div.videos{
            padding-top: 25px;
        }

        & div p{
            font-weight: bold;
            color: $black;
        }

        @media screen and (max-width: 980px){
            display:block;
            width: 99%;

            & div.images, div.videos{
                width: 50%;
                margin: 0 auto;
            }

            & div.images{
                margin-top: 10px;

                img{
                    margin: 0px;
                }
            }
        }
    }

    & .img{
        width: 100%;
    }

    @media screen and (max-width: 980px){
        display:block;
        width: 90%;
        margin: 0 auto;
        overflow: hidden;
        padding: 12px;
    }
}

.appendix{
    /*overflow: hidden;*/

    & figure{
        width: 50%;
        margin: 0 auto;
    }

    h5{
        font-size: 18px;
        margin-bottom: 8px;
    }


    & .appendix_table{        
        padding: 8px;
        margin-top: 12px;
        text-align: center;
        overflow-x: auto;

        h4{
            margin-top: 8px;
            margin-bottom: 0px;
        }

        h5{
            margin-bottom: 0px;
            margin-top: 4px;
            text-align: right;
            font-size: 18px;
            font-weight: 600;
        }

        & table{
            width: 100%;
            border-collapse: collapse;
            min-width: 900px;

            & thead{
                font-size: 14px;
                text-align: left;
            }

            & td.shaded{
                background: #c1c1c1;
            }
        }

        &.a2{
            border: 1px solid black;

            & table tr:last-child{
                font-size: 14px;
            }
        }

        

        &.borders table{
            border: 1px solid black;

            & td{
                border: 1px solid black;

                &.doubled_border{
                    border-style: double;
                    border-width: 3px;
                }
            }
        }

        & p{
            text-align: left;
        }

        & .shaded_red_fading{

            td{
                background: $table_red;
            }

            td:nth-child(2){
                background: lighten($table_red, 2%);
            }

            td:nth-child(3){
                background: lighten($table_red, 4%);
            }

            td:nth-child(4){
                background: lighten($table_red, 6%);
            }

            td:nth-child(5){
                background: lighten($table_red, 8%);
            }

            td:nth-child(6){
                background: lighten($table_red, 10%);
            }

            td:nth-child(7){
                background: lighten($table_red, 12%);
            }
        }

        & .shaded_blue_fading{

            td{
                background: $table_blue;
            }

            td:nth-child(2){
                background: lighten($table_blue, 3%);
            }

            td:nth-child(3){
                background: lighten($table_blue, 6%);
            }

            td:nth-child(4){
                background: lighten($table_blue, 9%);
            }

            td:nth-child(5){
                background: lighten($table_blue, 12%);
            }

            td:nth-child(6){
                background: lighten($table_blue, 15%);
            }

            td:nth-child(7){
                background: lighten($table_blue, 18%);
            }
        }
    }

    &.a4{
            & #last_p_article_info p{
                margin-top: 0px;
                font-size: 14px;
            }
        }

}

.article_loading{
    display: inline-block;
    width: calc(100% - 340px);
    text-align: center;
    vertical-align: top;
    margin: 20px;
    margin-top:10px;
    margin-right: 0px;
    background: $white;
    padding-left: 20px;
    overflow-y: auto;
    height: calc(100% - 25px) ;

    & img{
        height:200px;
    }
}

.slick-prev{
    left: 0;
    z-index: 2;
}

.slick-next{
    right: 0;
}

.article_back_button{
    height: 40px;
    margin-bottom: 0px;
    padding-left: 20px;
    margin-right: 0px;
    margin-top: 0px;
    padding-top: 10px;
}

.center{
    text-align: center;
}

.loading_img{
    height: 120px;
}

.menu_breadcrumb{


    & line{
        stroke:rgb(0,0,0);
        stroke-width:3;
    }
}

.bold{
    font-weight: bold;
}

.uppercase{
    text-transform: uppercase;
}

p.no_margin{
    margin: 0px;
}

p.small{
    font-size: 14px;
}

.mobile_article_sidebar.search{
    @media screen and (max-width: 425px) {
        position: relative;
        text-align: right;
        top: 0px;
    }
}